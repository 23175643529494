<template>
    <select class="form-control"  v-model="country">
        <option :value="null">Seleziona la nazione</option>
        <option v-for="(countryString, countryISO) in countriesOptions" :key="countryISO" :value="countryISO" :disabled="!countryString">
           {{countryString ? countryString : countryISO }}
        </option>
    </select>
</template>

<script>
    import {COUNTRIES} from "../../common/countries";

    export default {
        name: "SelectCountry",
        props: {
            defaultCountry: {
                type: String,
                required: false
            }
        },
        data: () => {
            return {
                country: null,
                countriesOptions: COUNTRIES
            }
        },
        mounted() {
            if (this.defaultCountry) {
                this.country = this.defaultCountry;
            }
        },
        watch: {
            defaultCountry: function(newDefaultCountry) {
              this.country = newDefaultCountry;
            },
            country: function(newCountry) {
                this.$emit('country-changed', newCountry)
            }
        }
    }

</script>
