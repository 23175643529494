<template>

    <table class="table table-condensed booking-table">
        <thead>
        <tr>
            <th>{{$t('common.site')}}</th>
            <th>#</th>
            <th class="text-right">{{$t('common.price')}}</th>
        </tr>
        </thead>
        <tbody id="table-price">
            <tr v-for="(data, key) in estimateReservation.reservation_item_grouped_by_product" :key="key">
                <td>{{StringsHelper.getProduct(parseInt(key))}}</td>
                <td nowrap="">
                    <span v-for="(reservation_item, index) in data" :key="index">{{reservation_item.quantity}} x {{StringsHelper.getRate(reservation_item.rate)}} <br></span>
                </td>
                <td class="text-right">
                    <span v-for="(reservation_item, index) in data" :key="index">
                        {{Utils.getCurrencySymbolFromString(reservation_item.price_currency)}}{{parseInt(reservation_item.price).toFixed(2)}}
                        <br>
                    </span>
                </td>
            </tr>
        </tbody>
        <tfoot>
        <tr class="active">
            <td colspan="2">
                {{$t('common.subtotal')}}
                <br>
                <span v-if="estimateReservation.additional_discount_perc">
                    <span>Sconto (Pacchetto famiglia {{estimateReservation.additional_discount_perc}}%)</span>
                </span>
                <span v-else-if="estimateReservation.discount_people && estimateReservation.price_discount > 0" id="discount-group-text">Sconto (tariffa gruppi)</span>
                <span v-else-if="estimateReservation.price_discount > 0" id="discount-monument-text">
                    Sconto (tariffa <span id="count_products">{{ Object.keys(estimateReservation.reservation_item_grouped_by_product).length}}</span> monumenti)
                </span>
            </td>
            <td class="text-right">
                <span id="price">€{{parseFloat(estimateReservation.price).toFixed(2)}}</span>
                <span id="price_discount" v-if="estimateReservation.price_discount > 0">-€{{parseFloat(estimateReservation.price_discount).toFixed(2)}}</span>
            </td>
        </tr>
        <tr>
            <th colspan="2">{{$t('common.total_paid')}}</th>
            <th class="text-right">€{{parseFloat(estimateReservation.price_final).toFixed(2)}}</th>
        </tr>
        </tfoot>
    </table>

</template>

<script>
    import baseMixin from "../../common/baseMixin";

    export default {
        name: "CashSummaryTable",
        mixins: [baseMixin],
        props: {
            estimateReservation: {
                type: Object
            },
        },
        mounted() {

        }
    }

</script>
